<template>
    <component :is="to ? NuxtLink : 'span'"
               :to="to ?? undefined"
               class="mon-link"
               :class="{ 'mon-link--hovered': isHovered }"
    >
        <slot />
        <IconChevron class="mon-link__arrow" :width="arrowWidth" aria-hidden />
    </component>
</template>

<script lang="ts" setup>

import type { NuxtLinkProps } from 'nuxt/app'
import { NuxtLink } from '#components'

const {
    to,
    size = 'md',
    isHovered = false,
} = defineProps<{
    to?: NuxtLinkProps['to']
    size?: 'xs' | 'sm' | 'md'
    isHovered?: boolean
}>()

const arrowWidth = computed<number>(() => ({
    xs: 10,
    sm: 12,
    md: 14,
}[size]))

</script>

<style lang="scss" scoped>

.mon-link {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    text-decoration: none;

    @include mon-text-base;
}

.mon-link__arrow {
    transform: rotate(-90deg);
    transition: transform $mon-trans-time-normal $mon-timing;
}

.mon-link:hover .mon-link__arrow,
.mon-link--hovered .mon-link__arrow {
    transform: rotate(-90deg) translateY(0.375rem);
}

</style>
